<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <div class="module_item">
        <div class="form_box">
          <el-form-item class="form_item" label="选择渠道：" prop="channel">
            <el-select v-model="formData.channel" placeholder="请选择" clearable @change="selectChange">
              <el-option v-for="(item,index) in channelList" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="选择页面：" prop="type">
            <el-select v-model="formData.type" placeholder="请选择" clearable @change="selectChange">
              <el-option v-for="(item,index) in pageList" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="选择坑位：" prop="parentNo">
            <el-select v-model="formData.parentNo" placeholder="请选择" @visible-change="selectPitChange" clearable>
              <el-option v-for="(item,index) in pitList" :key="index" :label="item.name" :value="item.locationNo"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="子坑位：" prop="subType">
            <el-input v-model="formData.subType"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="坑位描述：" prop="desc">
            <el-input v-model="formData.desc"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item class="form_item" label="封面图：" prop="imageSwitch">
            <el-switch
              v-model="formData.imageSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item class="form_item" label="标题：" prop="titleSwitch">
            <el-switch
              v-model="formData.titleSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item class="form_item" label="副标题：" prop="subTitleSwitch">
            <el-switch
              v-model="formData.subTitleSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2">
            </el-switch>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="form_btn">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isAdd: null, //是否为添加
        addType: '',                                //新建类型 1-新建链接 2-新建图文 3-新建视频
        formData: {
          channel: '',                        //渠道
          type: '',                           //页面
          parentNo: '',                       //坑位
          subType: '',                        //子坑位
          desc: '',                           //坑位描述
          status: 1,                          //状态
          imageSwitch: 2,                     //图片开关
          titleSwitch: 2,                     //标题开关
          subTitleSwitch: 2,                  //子标题开关
        },
        formRules: {
          subType: [
            { required: true, message: '请输入子坑位', trigger: 'blur' },
          ],
          channel: [
            { required: true, message: '请选择渠道', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择页面', trigger: 'change' },
          ],
          parentNo: [
            { required: true, message: '请选择坑位', trigger: 'change' },
          ]
        },
        option: {
          height: 500
        },
        html: '<p></p>',
        channelList: [],         //渠道列表
        pageList: [],            //页面列表
        pitList: [],             //坑位列表
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "渠道&坑位管理", path: "/management/content/contentDistribution/list" },
        {
          name: this.isAdd ? "新建子分类" :"修改子分类"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 渠道or页面选择
    selectChange(e){
      this.pitList = [];
      this.formData.parentNo = '';
      if(this.formData.channel&&this.formData.type){
        this.getPagePit();
      }
    },
    // 坑位选择
    selectPitChange(e){
      if(!e) return;
      if(!this.formData.channel){
        this.$message.warning('请先选择渠道');
        return;
      }
      if(!this.formData.type){
        this.$message.warning('请先选择页面');
        return
      }
    },
    
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          let path = ''
          if(this.isAdd){
            path = 'channelPitAdd'
          }else{
            path = 'channelPitEdit'
          }
          this.$store.dispatch(`management/${path}`,{
            ...this.formData,
            locationNo: this.$route.query.locationNo||''
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },



    // 获取channel数据
    getChannelList(){
      this.$store.dispatch('management/getContentChannelList',{}).then(res => {
        this.channelList = res.data;
      })
    },
    // 获取页面数据
    getPageList(){
      this.$store.dispatch('management/getContentPageList',{}).then(res => {
        this.pageList = res.data;
      })
    },
    // 获取坑位
    getPagePit(){
      this.$store.dispatch('management/contentModuleLocation',{
        channel: this.formData.channel,
        type: this.formData.type
      }).then(res => {
        this.pitList = res.data;
      })
    },
    getDetail(locationNo){
      this.$store.dispatch('management/channelPitDetail',{
        locationNo
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key];
        })
        this.formData.id = res.data.id;
        this.getPagePit();
        this.formData.parentNo = res.data.parentNo
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取channel数据
    this.getChannelList();
    // 获取页面数据
    this.getPageList();
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "channelPitAdd";
    this.pageName = this.$route.name;
    if (this.$route.query.locationNo) {
      this.getDetail(this.$route.query.locationNo);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        align-items: center;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
</style>
